<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <template #header>
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <div class="mt-2 font-italic">
                            Última actualización el <strong>{{ expense.updatedDate }}</strong> por <strong>{{ expense.updatedBy }}</strong>
                        </div>
                    </div>
                </div>
            </template>
            <b-row class="mt-2">
                <b-col cols="12" md="6">
                    <b-table-simple responsive bordered striped>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 25%; min-width: 115px;">Registrado</b-th>
                                <b-td>{{ expense.createdDate }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Elaborado por</b-th>
                                <b-td>{{ expense.createdBy }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Proveedor</b-th>
                                <b-td>{{ supplier.legalName }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Tipo de pago</b-th>
                                <b-td>{{ expense.paymentType === 'CASH' ? 'Contado' : 'Crédito' }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col class="col-md-6 text-right">
                    <h1 class="font-weight-bolder">
                        Gasto
                        <template v-if="expense.isExtra">extra</template>
                        <template v-else-if="expense.category === 'GENERAL'">general</template>
                    </h1>
                    <h1 class="text-navy font-weight-bolder">{{ expense.id }}</h1>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col cols="12">
                    <b-table-simple responsive bordered>
                        <b-thead>
                            <b-tr>
                                <b-th class="table-header-color" colspan="4">Datos generales</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr class="table-header-color">
                                <b-th style="width: 50%; min-width: 150px;">Descripción</b-th>
                                <b-th class="text-right" style="width: 16.6666%; min-width: 90px;">Cantidad</b-th>
                                <b-th class="text-right" style="width: 16.6666%; min-width: 90px;">Precio unitario</b-th>
                                <b-th class="text-right" style="width: 16.6666%; min-width: 90px;">Total</b-th>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ expense.name }}</b-td>
                                <b-td class="text-right">{{ expense.qty }}</b-td>
                                <b-td class="text-right">$ {{ formatCurrency(expense.unitPrice) }}</b-td>
                                <b-td class="text-right">$ {{ formatCurrency(expense.total) }}</b-td>
                            </b-tr>
                            <b-tr class="table-header-color">
                                <b-th colspan="4">Notas</b-th>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="4">{{ expense.notes ? expense.notes : 'Sin registro' }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <b-row class="mt-2" v-if="accountRp.id > 0">
                <b-col cols="12">
                    <b-table-simple responsive bordered>
                        <b-thead>
                            <b-tr>
                                <b-th class="table-header-color" colspan="5">Cuenta por pagar</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr class="table-header-color">
                                <b-th>Folio</b-th>
                                <b-th class="text-right">Importe</b-th>
                                <b-th class="text-right">Pagado</b-th>
                                <b-th class="text-right">Saldo</b-th>
                                <b-th class="text-center">Estatus</b-th>
                            </b-tr>
                            <b-tr>
                                <b-td><router-link :to="{ name: 'AccountRpShow', params: { id: accountRp.id }}" target="_blank">{{ accountRp.folio }}</router-link></b-td>
                                <b-td class="text-right">$ {{ formatCurrency(accountRp.amount) }}</b-td>
                                <b-td class="text-right">$ {{ formatCurrency(accountRp.paidReceiveAmount) }}</b-td>
                                <b-td class="text-right">$ {{ formatCurrency(accountRp.balance) }}</b-td>
                                <b-td class="text-center">
                                    <b-badge variant="warning" v-if="accountRp.status === 'BALANCE'">Por saldar</b-badge>
                                    <b-badge variant="primary" v-if="accountRp.status === 'PAID'">Pagada</b-badge>
                                    <b-badge variant="danger" v-if="accountRp.status === 'CANCELLED'">Cancelada</b-badge>
                                </b-td>
                            </b-tr>
                            <b-tr class="table-header-color">
                                <b-th colspan="5">Notas</b-th>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="5">{{ expense.notes ? expense.notes : 'Sin registro' }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'ExpenseIndex' }" class="mr-2">
                        <font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar
                    </b-button>
                    <b-button variant="secondary" :to="{ name: 'ExpenseEdit', params: { id: expense.id }}"
                              v-if="expense.id > 0 && expense.category === 'GENERAL'" class="mr-2">
                        <font-awesome-icon icon="edit"></font-awesome-icon> Modificar
                    </b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { expenseUrl } from '../../js/routes';
import * as constants from '@constants';
import { Expense } from '../../models/expense';
import { formatCurrency } from '../../js/utilities';

export default {
    data() {
        return {
            expense: new Expense(0, null, null, '', 'GENERAL', '', '', '', '', '', '', '', '', '', '', false, false),
            supplier: {
                id: 0,
                legalName: ''
            },
            accountRp: {
                id: 0,
                folio: '',
                amount: 0,
                paidReceiveAmount: 0,
                balance: 0,
                status: ''
            },
            lastExpense: null,
            swalTitle: 'Gastos',
            breadcrumb: {
                title: 'Gastos',
                path: [
                    {
                        name: 'ExpenseIndex',
                        text: 'Gastos'
                    }
                ]
            },
            businessEntities: [],
            paymentTypes: [
                { text: 'Contado', value: 'CASH' },
                { text: 'Crédito', value: 'CREDIT' }
            ],
            action: 'Nuevo',
            isLoading: false
        };
    },
    created() {
        this.breadcrumb.path.push({
            name: 'ExpenseShow',
            text: `Detalle`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);
        this.loadData();
    },
    methods: {
        formatCurrency: formatCurrency,
        loadData() {
            this.isLoading = true;

            this.axios.get(expenseUrl(this.$route.params.id), { params: { calledFrom: 'ExpenseShow' }}).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.expense = Expense.createFromObject(response.data.expense);
                    this.accountRp = response.data.accountRp;

                    if (response.data.supplier) {
                        this.supplier = response.data.supplier;
                    }
                }
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }
};
</script>

<style scoped>
</style>